import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React, { useEffect, useState } from 'react';
import SEO from '../components/SEO';
import { ANICOLOR } from '../theme/palette';
import { generateSlug } from '../utils';

//data.posts.nodes
const Portfolio = ({ data }) => {
  const [totalItm, setTotalItm] = useState(6);
  const [post, setPost] = useState([]);
  const [itmPort, setItmPort] = useState(0);

  const handleScroll = e => {
    const page = document.getElementById('news-section');

    let pageHeight = 300;
    if (typeof page !== 'undefined' && page !== null) {
      pageHeight = page.offsetHeight;
    }
    const itmLoad = document.getElementsByClassName('item-porto').length;

    if (e.target.documentElement.scrollTop >= pageHeight - 550) {
      if (itmLoad < data.posts.nodes.length) {
        const nt = totalItm + 6;
        setTotalItm(nt);
      }

      setItmPort(itmLoad);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (data) {
      setPost(data.posts.nodes.slice(0, totalItm));
    }
  }, [data, totalItm, itmPort]);

  return (
    <>
      <SEO title="Portofolio" />
      <section
        id="porto-section"
        className="portfolio-container pt-40 pb-8 px-2 sm:px-16 2xl:px-24  min-h-screen"
      >
        <h4 className="title-section">Portofolio</h4>
        <h3 className="subtitle-section">
          The result of
          <span className="px-3 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
            our work
          </span>
          with colleagues
        </h3>
        <div className="porto-wrapper ">
          {post.map((d, idx) => {
            const text = d.description.value;
            const maxLength = 100;

            let trimmedString = text.substr(0, maxLength);

            trimmedString = trimmedString.substr(
              0,
              Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
            );

            return (
              <div className="item item-porto">
                <AniLink
                  paintDrip
                  hex={ANICOLOR}
                  to={`/portofolio/${generateSlug(d.title.value)}`}
                >
                  <div className="img-wrapper">
                    <Image
                      fixed={d.main_image.value.childImageSharp.fixed}
                      className="img-porto !w-full !h-full"
                    />
                  </div>
                  <div className="px-3">
                    <h3 className="!text-xl">{d.title.value}</h3>
                    <p>{trimmedString}</p>
                    <span>See more</span>
                  </div>
                </AniLink>
              </div>
            );
          })}
        </div>

        {itmPort < data.posts.nodes.length ? (
          <div className="btn-wrapper text-center text-gray-600 mt-8">
            <a href="/portofolio/" className="hover:text-gray-800">
              Load More...
            </a>
          </div>
        ) : null}
      </section>
    </>
  );
};

export const query = graphql`
  query {
    posts: allCockpitPortofolio(filter: { active: { value: { eq: true } } }) {
      nodes {
        cockpitBy
        active {
          value
        }
        cockpitCreated
        cockpitId
        title {
          value
        }
        id
        description {
          value
        }
        content {
          value {
            id
          }
        }
        slug {
          value
        }
        main_image {
          value {
            childImageSharp {
              fixed(width: 480, toFormat: WEBP) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Portfolio;
