export const generateSlug = text =>
  encodeURIComponent(
    String(text)
      .trim()
      .toLowerCase()
      .replaceAll(/[\W_]+/g, '-')
  );

export const postCreators = {
  eb6451c264376559b9000396: 'Admin',
};
